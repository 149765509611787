.bg-b800 {
background-color:#1F43B2 !important;

}

.bg-700 {
  background-color: #E21454 !important;
  ;
}

.t-700 {
  color: #E21454 !important;
  ;
}

.bg-faq {
  background-color: #04021D !important;
  color: white !important;
}

.bg-inputs {
  background-color: #04021D !important;
  color: #BFDCFE !important;
}

.bg-dark2 {
  background-color: #04021D;
  color: white;
  position: relative;
}
 
.bg-950 {
  background-color: #172654;
}

.bg-200 {
  background-color: #BFDCFE;
}

.t-200 {
  color: #BFDCFE;
}

.bg-50 {
  background-color: #EFF6FF;
}

.r-950 {
  background-color: #4f021f;
}

.r-200 {
  background-color: #FFCBD6;
}

.rt-200 {
  color: #FFCBD6;
}

.r-50 {
  background-color: #FFF0F2;
} 
.custom-navbar .navbar-brand {
  flex-grow: 1; /* Ajusta según necesites */
}

.custom-navbar .nav-link {
  color: #fff !important; /* Asegúrate de que los enlaces sean blancos */
}

.bg-navbar {
  background-color: #050223 !important;
  
}

.bg-navbar2 {
  background-color: #050223 !important;
  opacity: 0.8 !important;
}

.bg-cardreview {
  background: #050223 !important;
  opacity: 0.7 !important;
  
}

.navbar-right {
  margin-right: auto;

}
.home-background {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh; /* Ajusta según necesites */
}

.text-column {
  color: white; /* Asegúrate de que el texto sea legible sobre tu imagen de fondo */
}

.font-f {
  font-family: 'Libre Franklin', sans-serif; /* Asegúrate de que esta fuente esté importada en tu proyecto */
}

.text-font {
  color: white !important; /* Asegúrate de que el texto sea legible sobre tu imagen de fondo */
}

.tr-card {
  color: #FFCBD6, !important; /* Asegúrate de que el texto sea legible sobre tu imagen de fondo */
}

.custom-card {
  width: 330px;
  height: auto;
}

.degraded-background {
  background: conic-gradient(from 270deg at 50% 50%, rgba(233, 110, 148, 0.2) 0deg, rgba(4, 2, 29, 0) 360deg);
}

.degraded-background2 {
  background: conic-gradient(from 90deg at 50% 50%, rgba(42, 84, 214, 0.2) 0deg, rgba(4, 2, 29, 0) 360deg);
}

.degraded.index {
  background: linear-gradient(180deg, rgba(4, 2, 29, 0) 0%, #04021D 96%);
}

.step-image {
  height: 3.5rem;
  width: 3.5rem;
  object-fit: contain;
  padding-left: 30px;
  /* Ajusta esto según tus necesidades */
  /* width: auto; /* Esto mantiene la proporción de la imagen */
 /*  object-fit: cover; /* Esto asegura que la imagen cubra el espacio sin distorsionarse */
}

.custom-card-body {
  display: flex !important; 
  flex-direction: column !important;
  align-items: center !important;
}


.bg-infort {
  
  background: conic-gradient(from 180deg at 50% 50%, rgba(4, 2, 29, 0.2) 0deg, rgba(42, 84, 214, 0.2) 360deg);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%; /* Se asegura de que al menos tenga la altura del contenido */
  /* demás propiedades de fondo */
}

.image-infort {
  height: 240px;
  width: 380px;
  object-fit: contain;
}

.bg-ways {

background: rgba(191, 220, 254, 0.1);

}

.image-ways {
  width: 226px;
  height: 110px;
  object-fit: contain; 
}

.font-ways {

  font: 'Arimo Hebrew Subset', sans-serif
}

.bg-services {
  
  background: linear-gradient(180deg, rgba(4, 2, 29, 0) 0%, #04021D 100%);
}


.bg-cardreview-step {
  background: rgba(2, 15, 38, 0.6) !important;
}

.custom-input::placeholder{
  color: #4e4e5094 !important;
  font-weight: 500;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

@media (max-width: 576px) {
  .px-6 {
    padding-left: 16% !important;
    padding-right: 16% !important;
  }
}

.w-img {
  width: 5vw !important; 
}

@media (max-width: 576px) {
  .w-img {
    width: 12vw !important;
  }
}

.text-no-dec{
  text-decoration: none !important;
}

/* Arimo for H1-H4 with a fallback */
h1, h2, h3, h4 {
  font-family: 'Arimo', sans-serif !important;
}

/* Libre Franklin for H5, H6, and paragraphs */
h5, h6, p {
  font-family: 'Libre Franklin', sans-serif !important;
}


.bg-card-dark {
  background: #04021D;
}

.bg-card-pink {
  background: #E21454;
}

.text-blue {
  color: #1F43B2 !important;
}